import { Component } from '@angular/core';
import { CurrencyService } from '../../../../shared/services/currency.service';
import { AuthService } from '../../../../shared/services/auth.service';
@Component({
    selector: 'app-header-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {
    

    languages = [
        { name: 'La Tamacua', image: 'language-1' }
       // { name: 'Morelos', image: 'language-1' }
    ];

    currencies = [
        { name: 'La Tamacua', url: '', code: 'La Tamacua', symbol: '$' }
       // { name: 'Morelos', url: '', code: 'Morelos', symbol: '$' },
    ];

    constructor(
        public currencyService: CurrencyService,
        public auth: AuthService
    ) { }

    setCurrency(currency): void {
        this.currencyService.options = {
            code: currency.code,
            display: currency.symbol,
        };
    }
}
