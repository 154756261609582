import { Component, Input, OnInit } from '@angular/core';
import { StoreService } from '../../shared/services/store.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Input() layout: 'classic' | 'compact' = 'classic';
    roles_: any;
    constructor(public store: StoreService, public auth: AuthService) {

    }

    ngOnInit() {

        this.auth.user.subscribe(data => {

            if (data) {

                if (data) {
                    this.roles_ = data.roles;
                }
            }
        });
    }

    localSave(number) {
        localStorage.setItem('precioOption', number);
        location.reload();
    }
}
