import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable, BehaviorSubject } from "rxjs";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Product } from 'src/app/shared/interfaces/product';

//tets
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { productosNombres } from './data';

const states = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado',
  'Connecticut', 'Delaware', 'District Of Columbia', 'Federated States Of Micronesia', 'Florida', 'Georgia',
  'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine',
  'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana',
  'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota',
  'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island',
  'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Islands', 'Virginia',
  'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: "ngbd-modal-content",
  template: `
    <div class="modal-body">
      <mat-form-field floatLabel="always" width="100%">
        <input
          matInput
          (keyup)="applyFilter($event.target.value)"
          placeholder="Filter"
        />
      </mat-form-field>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table">
        
      <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Codigo</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>
      <!-- Position Column -->
        <ng-container matColumnDef="img">
          <th mat-header-cell *matHeaderCellDef>Imagen</th>
          <td mat-cell *matCellDef="let element">
            <img width="60px" height="60px" [src]="element.images[0]" />
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="ver">
          <th mat-header-cell *matHeaderCellDef>Ver</th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="[ '/tienda/shop/', element?.category_type, element?.category, element?.id]"
              (click)="activeModal.close('Close click')"
              >Ver</a
            >
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10]"></mat-paginator>

      <!--Body-->
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="activeModal.close('Close click')"
      >
        Cerrar
      </button>
    </div>
  `
})
export class NgbdModalContent {
  displayedColumns: string[] = ["id", "img", "nombre", "ver"];
  dataSource: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  @Input() name;
  searchText;
  heroes: any;
  items$: Observable<Product[]>;
  categoryFilter$: BehaviorSubject<string | null>;
  constructor(public activeModal: NgbActiveModal, afs: AngularFirestore) {
    this.categoryFilter$ = new BehaviorSubject(null);
    this.items$ = Observable.combineLatest(this.categoryFilter$).switchMap(
      ([category]) =>
        afs
          .collection<Product>("products", ref => {
            let query: firebase.firestore.Query = ref;
            if (category) {
              query = query.where("category_type", "==", category);
            }
            return query;
          })
          .valueChanges()
    );

    this.items$.subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.heroes = data;
    });
  }
}

@Component({
  selector: "Search-Bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"]
})
export class SearchBarComponent implements OnInit {

  constructor(private modalService: NgbModal, private router: Router) {

   }

  ngOnInit() { }

  open() {
    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.name = "World";
  }


  showResults = false;

  

  searchChanged(query) {
    if (query.length) {
      this.showResults = true;
    } else {
      this.showResults = false;
    }
  }
  // test

  model = ""; 

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? productosNombres
        : productosNombres.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  btnClick = function () {
    this.router.navigateByUrl('tienda/shop/search/product/s/' + this.model +"?refresh=1");
   // this.router.navigate(['tienda/shop/search/product/s/' + this.model])
    console.log(this.model)
  };
  
}
