import { NavigationLink } from '../app/shared/interfaces/navigation-link';

export const navigation: NavigationLink[] = [
    {
        label: 'Departamentos', url: './shop', menu: {
            type: 'menu',
            items: [
                {
                    label: 'Canalización', url: './shop/products/Canalización/', items: [
                        { label: 'Abrazadera', url: './shop/CANALIZACION/ABRAZADERA' },
                        { label: 'Armario', url: './shop/CANALIZACION/ARMARIO' },
                        { label: 'Base', url: './shop/CANALIZACION/BASE' },
                        { label: 'Bote', url: './shop/CANALIZACION/BOTE' },
                        { label: 'Brazo', url: './shop/CANALIZACION/BRAZO' },
                        { label: 'Caja', url: './shop/CANALIZACION/CAJA' },
                        { label: 'Canaleta', url: './shop/CANALIZACION/CANALETA' },
                        { label: 'Codos', url: './shop/CANALIZACION/CODOS' },
                        { label: 'Barra', url: './shop/CANALIZACION/BARRA' },
                        { label: 'Condulet', url: './shop/CANALIZACION/CONDULET' },
                        { label: 'Conduit', url: './shop/CANALIZACION/CONDUIT' },
                        { label: 'Conector', url: './shop/CANALIZACION/CONECTOR' },
                        { label: 'Contador', url: './shop/CANALIZACION/CONTACTOR' },
                        { label: 'Zapata', url: './shop/CANALIZACION/ZAPATA' },
                        { label: 'Cople', url: './shop/CANALIZACION/COPLE' },
                        { label: 'Sello', url: './shop/CANALIZACION/SELLO' },
                        { label: 'Barra', url: './shop/CANALIZACION/BARRA' },
                        { label: 'Coble', url: './shop/CANALIZACION/COPLE' },
                        { label: 'Cuadralet', url: './shop/CANALIZACION/CUADRALET' },
                        { label: 'Monitor', url: './shop/CANALIZACION/MONITOR' },
                        { label: 'Mufa', url: './shop/CANALIZACION/MUFA' },
                        { label: 'Ovalet', url: './shop/CANALIZACION/OVALET' },
                        { label: 'Poliducto', url: './shop/CANALIZACION/POLIDUCTO' },
                        { label: 'Tubo', url: './shop/CANALIZACION/TUBO' },
                        { label: 'Varilla', url: './shop/CANALIZACION/VARILLA' },
                        { label: 'Reducciòn', url: './shop/CANALIZACION/REDUCCION' },
                        { label: 'Ducto', url: './shop/CANALIZACION/DUCTO' },
                        { label: 'Fusible', url: './shop/CANALIZACION/FUSIBLE' },
                        { label: 'Gabinete', url: './shop/CANALIZACION/GABINETE' },
                        { label: 'Guia jala cable', url: './shop/CANALIZACION/GUIA JALA CABLE' },
                        { label: 'Interruptor', url: './shop/CANALIZACION/INTERRUPTOR' },
                    ]
                        
                },
                {
                    label: 'Iluminación', url: './shop', items: [
                        { label: 'Bombillo', url: './shop/ILUMINACION/BOMBILLO' },
                        { label: 'Arbotante', url: './shop/ILUMINACION/ARBOTANTE' },
                        { label: 'Candil', url: './shop/ILUMINACION/CANDIL' },
                        { label: 'Colgante', url: './shop/ILUMINACION/COLGANTE' },
                        { label: 'Empotrado', url: './shop/ILUMINACION/EMPOTRADO' },
                        { label: 'Farol', url: './shop/ILUMINACION/FAROL' },
                        { label: 'Luminaria', url: './shop/ILUMINACION/LUMINARIA' },
                        { label: 'Gabinete', url: './shop/ILUMINACION/GABINETE' },
                        { label: 'Poste', url: './shop/ILUMINACION/POSTE' },
                        { label: 'Lampara', url: './shop/ILUMINACION/LAMPARA' },
                        { label: 'Suburbana', url: './shop/ILUMINACION/SUBURBANA' },
                        { label: 'Plafon', url: './shop/ILUMINACION/PLAFON' },
                        { label: 'Reflector', url: './shop/ILUMINACION/REFLECTOR' },
                        { label: 'Riel', url: './shop/ILUMINACION/RIEL' },
                        { label: 'Tira Led', url: './shop/ILUMINACION/TIRA LED' },
                        { label: 'Tubo Led', url: './shop/ILUMINACION/TUBO LED' },
                        { label: 'Panel Led', url: './shop/ILUMINACION/PANEL LED' },
                    ]
                },
                {
                    label: 'Material Electrico', url: './shop', items: [
                        { label: 'Clavija', url: './shop/MATERIAL ELECTRICO/CLAVIJA' },
                        { label: 'Apagador', url: './shop/MATERIAL ELECTRICO/APAGADOR' },
                        { label: 'Conector', url: './shop/MATERIAL ELECTRICO/CONECTOR' },
                        { label: 'Campana', url: './shop/MATERIAL ELECTRICO/CAMPANA' },
                        { label: 'Cinta', url: './shop/MATERIAL ELECTRICO/CINTA' },
                        { label: 'Contacto', url: './shop/MATERIAL ELECTRICO/CONTACTO' },
                        { label: 'Extersiòn', url: './shop/MATERIAL ELECTRICO/EXTENSION' },
                        { label: 'Respuesto', url: './shop/MATERIAL ELECTRICO/REPUESTO' },
                        { label: 'Tapas', url: './shop/MATERIAL ELECTRICO/TAPAS' },
                        { label: 'Timbre', url: './shop/MATERIAL ELECTRICO/TIMBRE' },
                        { label: 'Toma', url: './shop/MATERIAL ELECTRICO/TOMA' },
                        { label: 'Interfon', url: './shop/MATERIAL ELECTRICO/INTERFON' },
                        { label: 'Placas', url: './shop/MATERIAL ELECTRICO/PLACAS' },
                        { label: 'Socket', url: './shop/MATERIAL ELECTRICO/SOCKET' },
                        { label: 'Timmer', url: './shop/MATERIAL ELECTRICO/TIMMER' },
                        { label: 'Ventilador y extractor', url: './shop/MATERIAL ELECTRICO/VENTILADOR Y EXTRACTOR' },
                        { label: 'Cono Spot', url: './shop/MATERIAL ELECTRICO/CONO SPOT' },
                        { label: 'Multimetro', url: './shop/MATERIAL ELECTRICO/MULTIMETRO' },
                        { label: 'Adaptador', url: './shop/MATERIAL ELECTRICO/ADAPTADOR' },
                        { label: 'Pastillas', url: './shop/MATERIAL ELECTRICO/PASTILLAS' },
              
                    ]
                },
                {
                    label: 'Distribución y Control', url: './shop', items: [
                        { label: 'Adaptador', url: './shop/DISTRIBUCION Y CONTROL/ADAPTADOR' },
                        { label: 'Balastra', url: './shop/DISTRIBUCION Y CONTROL/BALASTRA' },
                        { label: 'Fuente', url: './shop/DISTRIBUCION Y CONTROL/FUENTE' },
                        { label: 'Divisor', url: './shop/DISTRIBUCION Y CONTROL/DIVISOR' },
                        { label: 'Fotocelda', url: './shop/DISTRIBUCION Y CONTROL/FOTOCELDA' },
                        { label: 'Gabinete', url: './shop/DISTRIBUCION Y CONTROL/GABINETE' },
                        { label: 'Pastillas', url: './shop/DISTRIBUCION Y CONTROL/PASTILLAS' },
                        { label: 'Sensor', url: './shop/DISTRIBUCION Y CONTROL/SENSOR' },
                        { label: 'Centro de carga', url: './shop/DISTRIBUCION Y CONTROL/CENTRO DE CARGA' },
                        { label: 'Probador', url: './shop/DISTRIBUCION Y CONTROL/PROBADOR' },
                        { label: 'Pastillas', url: './shop/DISTRIBUCION Y CONTROL/PASTILLAS' },
                        { label: 'Motor', url: './shop/DISTRIBUCION Y CONTROL/MOTOR' },
                        { label: 'Interruptor', url: './shop/DISTRIBUCION Y CONTROL/INTERRUPTOR' },
                        { label: 'Fuente', url: './shop/DISTRIBUCION Y CONTROL/FUENTE' },
                        { label: 'Controlador', url: './shop/DISTRIBUCION Y CONTROL/CONTROLADOR' },
                        { label: 'Bomba', url: './shop/DISTRIBUCION Y CONTROL/BOMBA' },
                
                    ]
                
                },
                {
                    label: 'Conductores', url: './shop', items: [
                        { label: 'Cable', url: './shop/CONDUCTORES/CABLE' },
                        { label: 'Empalmes', url: './shop/CONDUCTORES/EMPALMES' },
                        { label: 'Espiral', url: './shop/CONDUCTORES/ESPIRAL' },
                    ]

                },
                {
                    label: 'Ferreteria', url: './shop', items: [
                        { label: 'Broca', url: './shop/FERRETERIA/BROCA' },
                        { label: 'Cincho', url: './shop/FERRETERIA/CINCHO' },
                        { label: 'Campana', url: './shop/FERRETERIA/CAMPANA' },
                        { label: 'Herramienta', url: './shop/FERRETERIA/HERRAMIENTA' },
                        { label: 'Terminal', url: './shop/FERRETERIA/TERMINAL' },
                        { label: 'Tornilleria', url: './shop/FERRETERIA/TORNILLERIA' },
                        { label: 'Zapata', url: './shop/FERRETERIA/ZAPATA' },
                        { label: 'Derivador', url: './shop/FERRETERIA/DERIVADOR' },
                        { label: 'Varios', url: './shop/FERRETERIA/VARIOS' },
                    ]
                },
            ]
        }
    },
    {
        label: 'Servicios', url: './site', menu: {
            type: 'menu',
            items: [
                { label: 'Directorio', url: './site/servicios' },

            ]
        }
    },
    /*{
        label: 'Promociones y Eventos', url: './site', menu: {
            type: 'menu',
            items: [
                { label: 'Catalogo', url: './site/catalogo' },
            ]
        }
    }*/
];
