import { Component, OnInit } from '@angular/core';
import { MobileMenuService } from '../../../../shared/services/mobile-menu.service';
import { WishlistService } from '../../../../shared/services/wishlist.service';
import { CartService } from '../../../../shared/services/cart.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
    selector: 'app-mobile-header',
    templateUrl: './mobile-header.component.html',
    styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit {
    constructor(
        public menu: MobileMenuService,
        public wishlist: WishlistService,
        public cart: CartService,
        public auth: AuthService
    ) { }

    // var
    roles_: any;

    ngOnInit() {

        this.auth.user.subscribe(data => {

            if (data) {

                if (data) {
                    this.roles_ = data.roles;
                }
            }
        });
    }

    localSave(number) {
        localStorage.setItem('precioOption', number);
        location.reload();
    }
}
