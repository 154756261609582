export const categories = [
    {
        title: 'Iluminación', url: '../shop', products: 572, image: 'assets/images/categories/category-1.png', subcategories: [
            { title: 'Bombillo', url: '../shop/ILUMINACION/BOMBILLO' },
            { title: 'Arbotante', url: '../shop/ILUMINACION/ARBOTANTE' },
            { title: 'Candil', url: '../shop/ILUMINACION/CANDIL' },
            { title: 'Colgante', url: '../shop/ILUMINACION/COLGANTE' },
            { title: 'Empotrado', url: '../shop/ILUMINACION/EMPOTRADO' },
        ]
    },
    {
        title: 'Conductores', url: '../shop', products: 134, image: 'assets/images/categories/category-2.png', subcategories: [
            { title: 'Cable', url: '../shop/CONDUCTORES/CABLE' },
            { title: 'Empalmes', url: '../shop/CONDUCTORES/EMPALMES' },
            { title: 'Espiral', url: '../shop/CONDUCTORES/ESPIRAL' },
        ]
    },
    {
        title: 'Distribución y Control', url: '../shop', products: 301, image: 'assets/images/categories/category-4.png', subcategories: [
            { title: 'Adaptador', url: '../shop/DISTRIBUCION Y CONTROL/ADAPTADOR' },
            { title: 'Balastra', url: '../shop/DISTRIBUCION Y CONTROL/BALASTRA' },
            { title: 'Fuente', url: '../shop/DISTRIBUCION Y CONTROL/FUENTE' },
            { title: 'Divisor', url: '../shop/DISTRIBUCION Y CONTROL/DIVISOR' },
            { title: 'Fotocelda', url: '../shop/DISTRIBUCION Y CONTROL/FOTOCELDA' },
        ]
    },
    {
        title: 'Canalización', url: '../shop', products: 79, image: 'assets/images/categories/category-3.png', subcategories: [
            { title: 'Abrazadera', url: '../shop/CANALIZACION/ABRAZADERA' },
            { title: 'Armario', url: '../shop/CANALIZACION/ARMARIO' },
            { title: 'Base', url: '../shop/CANALIZACION/BASE' },
            { title: 'Bote', url: '../shop/CANALIZACION/BOTE' },
            { title: 'Brazo', url: '../shop/CANALIZACION/BRAZO' },
        ]
    },
    {
        title: 'Material Electrico', url: '../shop', products: 366, image: 'assets/images/categories/category-5.png', subcategories: [
            { title: 'Clavija', url: '../shop/MATERIAL ELECTRICO/CLAVIJA' },
            { title: 'Apagador', url: '../shop/MATERIAL ELECTRICO/APAGADOR' },
            { title: 'Conector', url: '../shop/MATERIAL ELECTRICO/CONECTOR' },
            { title: 'Campana', url: '../shop/MATERIAL ELECTRICO/CAMPANA' },
            { title: 'Cinta', url: '../shop/MATERIAL ELECTRICO/CINTA' },
        ]
    },
    {
        title: 'Ferreteria', url: '../shop', products: 81, image: 'assets/images/categories/category-6.png', subcategories: [
            { title: 'Broca', url: '../shop/FERRETERIA/BROCA' },
            { title: 'Cincho', url: '../shop/FERRETERIA/CINCHO' },
            { title: 'Campana', url: '../shop/FERRETERIA/CAMPANA' },
            { title: 'Herramienta', url: '../shop/FERRETERIA/HERRAMIENTA' },
            { title: 'Terminal', url: '../shop/FERRETERIA/TERMINAL' },
        ]
    }
];
