import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs/observable';
import { of } from 'rxjs';
import { Product } from '../interfaces/product';


type productsCollection = AngularFirestoreCollection<Product>;
type productDocument = AngularFirestoreDocument<Product>;
type productCollection = AngularFirestoreCollection<Product>;

@Injectable({
  providedIn: 'root'
})
export class ProductService {


  constructor(
    private afs: AngularFirestore,
  ) { }
  // obtener todos los productos
  getProducts(): productsCollection {
    return this.afs.collection<Product>('products');
  }


  // Obtener un solo producto
  product(id: string): productDocument {
    return this.afs.doc<Product>(`products/${id}`);
  }


}
