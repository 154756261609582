import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { Observable, of } from 'rxjs';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import { AngularFireFunctions } from '@angular/fire/functions';
import { User } from '../interfaces/user.model';
import { Location } from '@angular/common';

@Injectable()
export class AuthService {

  public user: Observable<User>;

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private fun: AngularFireFunctions,
    private _location: Location

  ) {
    this.user = this.afAuth.authState.switchMap((user) => {
      if (user) {
        return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
      } else {
        return Observable.of(null);
      }
    });
  }

  public googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }

  public facebookLogin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.oAuthLogin(provider);
  }

  public emailAndPassword(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  public signUp(email, password) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
  }

  public signOut() {
    this.afAuth.auth.signOut().then(() => {
      this.router.navigate(['/']);
    });
  }



  private oAuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider).then((credentials) => {
      const user = credentials.user;
      this.afs.collection<User>('users', (ref) => ref.where('email', '==', user.email)).valueChanges().subscribe(
        (data) => {
          if (!data.length) {
            const newUser = {
              uid: user.uid,
              email: user.email,
              displayName: user.displayName,
              photoUrl: user.photoURL,
              roles: 1,
              phone: user.phoneNumber,
              name: '',
              lastName: ''
            };



            this.afs.collection('users').doc(user.uid).set(newUser).then(() => {
              return;
            });

          }
          this.router.navigate(['/home']);
          //this._location.back();
        });
    });
  }

  


}
