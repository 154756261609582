import { Component } from '@angular/core';
import { posts } from '../../../data/blog-posts';
import { brands } from '../../../data/shop-brands';
//import { products } from '../../../data/shop-products';
import { categories } from '../../../data/shop-block-categories';
//service
import { ProductService } from '../../shared/services/product.service';
import { Product } from 'src/app/shared/interfaces/product';
// Rxjs
import "rxjs/add/observable/combineLatest";
import "rxjs/add/operator/switchMap";
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService } from 'src/app/shared/services/app.service';

@Component({
  selector: 'app-page-home-three',
  templateUrl: './page-home-three.component.html',
  styleUrls: ['./page-home-three.component.scss']
})
export class PageHomeThreeComponent {
  // Variables
  products: Product[] = [];
  product_: any;
  categories = categories;
  posts = posts;
  brands = brands;

  columns = [
    {
      header: 'Mas Vistos',
      products: this.products.slice(0, 3)
    },
    {
      header: 'Ofertas Especiales',
      products: this.products.slice(3, 6)
    },
    {
      header: 'Mas Vendidos',
      products: this.products.slice(6, 9)
    }
  ];
 // featured L
  featuredProducts = {
    loading: false,
    products: this.products.slice(),
    groups: [
      { name: 'Todo', current: true },
      { name: 'Canalizaciòn', current: false },
      { name: 'Iluminaciòn', current: false },
      { name: 'Material Electrico', current: false },
      { name: 'Distribuciòn y Control', current: false },
      { name: 'Conductores', current: false },
      { name: 'Ferreteria', current: false }
    ],

    timeout: null, // only for demo

    groupChange: group => {
      // only for demo
      this.featuredProducts.loading = true;

      clearTimeout(this.featuredProducts.timeout);

      this.featuredProducts.timeout = setTimeout(() => {
        const itemsArray = this.featuredProducts.products;
        const newItemsArray = [];
        while (itemsArray.length > 0) {
          const randomIndex = Math.floor(Math.random() * itemsArray.length);
          const randomItem = itemsArray.splice(randomIndex, 1)[0];
          newItemsArray.push(randomItem);
        }
        this.featuredProducts.products = newItemsArray;
        this.featuredProducts.loading = false;
      }, 1000);
    }
  };
// new arrival
  newArrivals = {
    loading: false,
    products: this.products.slice(),
    groups: [
      { name: 'Todo', current: true },
      { name: 'Canalizaciòn', current: false },
      { name: 'Iluminaciòn', current: false },
      { name: 'Material Electrico', current: false },
      { name: 'Distribuciòn y Control', current: false },
      { name: 'Conductores', current: false },
      { name: 'Ferreteria', current: false }
    ],

    timeout: null, // only for demo

    groupChange: group => {
      // only for demo
      this.newArrivals.loading = true;

      clearTimeout(this.newArrivals.timeout);

      this.newArrivals.timeout = setTimeout(() => {
        const itemsArray = this.newArrivals.products;
        const newItemsArray = [];
        while (itemsArray.length > 0) {
          const randomIndex = Math.floor(Math.random() * itemsArray.length);
          const randomItem = itemsArray.splice(randomIndex, 1)[0];
          newItemsArray.push(randomItem);
        }
        this.newArrivals.products = newItemsArray;
        this.newArrivals.loading = false;
      }, 1000);
    }
  };

// test
  items$: Observable<Product[]>;
  categoryFilter$: BehaviorSubject<string | null>;
  
  constructor(afs: AngularFirestore, public appService: AppService ) {
    this.appService.fireLoader();
 // obtener productos a listar
    this.categoryFilter$ = new BehaviorSubject(null);
    this.items$ = Observable.combineLatest(
      this.categoryFilter$,
    ).switchMap(([category]) =>
      afs
        .collection<Product>("products", ref => {
          let query: firebase.firestore.Query = ref.limit(10).where("visible", "==", "SI");
          if (category) {
            query = query.where("category_type", "==", category);
          }
          return query;
        })
        .valueChanges()
    );  


    this.items$.subscribe(datos => {
      this.products = datos;
      if (datos) {
        this.updateSliders();
      }
    });

  }

  updateSliders(){
    // Actulizar 
   this.featuredProducts = {
      loading: false,
      products: this.products.slice(),
      groups: [
        { name: 'Todo', current: true },
        { name: 'Canalizaciòn', current: false },
        { name: 'Iluminaciòn', current: false },
        { name: 'Material Electrico', current: false },
        { name: 'Distribuciòn y Control', current: false },
        { name: 'Conductores', current: false },
        { name: 'Ferreteria', current: false }
      ],

      timeout: null, // only for demo

      groupChange: group => {
        // only for demo
        this.featuredProducts.loading = true;

        clearTimeout(this.featuredProducts.timeout);

        this.featuredProducts.timeout = setTimeout(() => {
          const itemsArray = this.featuredProducts.products;
          const newItemsArray = [];
          while (itemsArray.length > 0) {
            const randomIndex = Math.floor(Math.random() * itemsArray.length);
            const randomItem = itemsArray.splice(randomIndex, 1)[0];
            newItemsArray.push(randomItem);
          }
          this.featuredProducts.products = newItemsArray;
          this.featuredProducts.loading = false;
        }, 1000);
      }
    };

    this.newArrivals = {
      loading: false,
      products: this.products.slice(),
      groups: [
        { name: 'Todo', current: true },
        { name: 'Canalizaciòn', current: false },
        { name: 'Iluminaciòn', current: false },
        { name: 'Material Electrico', current: false },
        { name: 'Distribuciòn y Control', current: false },
        { name: 'Conductores', current: false },
        { name: 'Ferreteria', current: false }
      ],

      timeout: null, // only for demo

      groupChange: group => {
        // only for demo
        this.newArrivals.loading = true;

        clearTimeout(this.newArrivals.timeout);

        this.newArrivals.timeout = setTimeout(() => {
          const itemsArray = this.newArrivals.products;
          const newItemsArray = [];
          while (itemsArray.length > 0) {
            const randomIndex = Math.floor(Math.random() * itemsArray.length);
            const randomItem = itemsArray.splice(randomIndex, 1)[0];
            newItemsArray.push(randomItem);
          }
          this.newArrivals.products = newItemsArray;
          this.newArrivals.loading = false;
        }, 1000);
      }
    };
  }

  ngOnInit() {
    this.items$.subscribe(datos => {
      this.products = datos;
      if (datos) {
        this.updateSliders();
        this.appService.stopLoader();
      }
    });
  }

}
