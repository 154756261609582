import { MobileMenuItem } from '../app/shared/interfaces/mobile-menu-item';

export const mobileMenu: MobileMenuItem[] = [
    {
        type: 'link', label: 'Inicio', url: './'
    },
    {
        type: 'nolink', label: 'Categorias', url: '', children: [
            {
                type: 'nolink', label: 'Canalización', url: '', children: [
                    { type: 'link', label: 'Abrazadera', url: './shop/CANALIZACION/ABRAZADERA' },
                    {type: 'link', label: 'Abrazadera', url: './shop/CANALIZACION/ABRAZADERA' },
                    {type: 'link', label: 'Armario', url: './shop/CANALIZACION/ARMARIO' },
                    {type: 'link', label: 'Base', url: './shop/CANALIZACION/BASE' },
                    {type: 'link', label: 'Bote', url: './shop/CANALIZACION/BOTE' },
                    {type: 'link', label: 'Brazo', url: './shop/CANALIZACION/BRAZO' },
                    {type: 'link', label: 'Caja', url: './shop/CANALIZACION/CAJA' },
                    {type: 'link', label: 'Canaleta', url: './shop/CANALIZACION/CANALETA' },
                    {type: 'link', label: 'Codos', url: './shop/CANALIZACION/CODOS' },
                    {type: 'link', label: 'Barra', url: './shop/CANALIZACION/BARRA' },
                    {type: 'link', label: 'Condulet', url: './shop/CANALIZACION/CONDULET' },
                    {type: 'link', label: 'Conduit', url: './shop/CANALIZACION/CONDUIT' },
                    {type: 'link', label: 'Conector', url: './shop/CANALIZACION/CONECTOR' },
                    {type: 'link', label: 'Contador', url: './shop/CANALIZACION/CONTACTOR' },
                    {type: 'link', label: 'Zapata', url: './shop/CANALIZACION/ZAPATA' },
                    {type: 'link', label: 'Cople', url: './shop/CANALIZACION/COPLE' },
                    {type: 'link', label: 'Sello', url: './shop/CANALIZACION/SELLO' },
                    {type: 'link', label: 'Barra', url: './shop/CANALIZACION/BARRA' },
                    {type: 'link', label: 'Coble', url: './shop/CANALIZACION/COPLE' },
                    {type: 'link', label: 'Cuadralet', url: './shop/CANALIZACION/CUADRALET' },
                    {type: 'link', label: 'Monitor', url: './shop/CANALIZACION/MONITOR' },
                    {type: 'link', label: 'Mufa', url: './shop/CANALIZACION/MUFA' },
                    {type: 'link', label: 'Ovalet', url: './shop/CANALIZACION/OVALET' },
                    {type: 'link', label: 'Poliducto', url: './shop/CANALIZACION/POLIDUCTO' },
                    {type: 'link', label: 'Tubo', url: './shop/CANALIZACION/TUBO' },
                    {type: 'link', label: 'Varilla', url: './shop/CANALIZACION/VARILLA' },
                    {type: 'link', label: 'Reducciòn', url: './shop/CANALIZACION/REDUCCION' },
                    {type: 'link', label: 'Ducto', url: './shop/CANALIZACION/DUCTO' },
                    {type: 'link', label: 'Fusible', url: './shop/CANALIZACION/FUSIBLE' },
                    {type: 'link', label: 'Gabinete', url: './shop/CANALIZACION/GABINETE' },
                    {type: 'link', label: 'Guia jala cable', url: './shop/CANALIZACION/GUIA JALA CABLE' },
                    {type: 'link', label: 'Interruptor', url: './shop/CANALIZACION/INTERRUPTOR' },
                ]
            },
            {
                type: 'nolink', label: 'Iluminación', url: '', children: [
                    {type: 'link', label: 'Bombillo', url: './shop/ILUMINACION/BOMBILLO' },
                    {type: 'link', label: 'Arbotante', url: './shop/ILUMINACION/ARBOTANTE' },
                    {type: 'link', label: 'Candil', url: './shop/ILUMINACION/CANDIL' },
                    {type: 'link', label: 'Colgante', url: './shop/ILUMINACION/COLGANTE' },
                    {type: 'link', label: 'Empotrado', url: './shop/ILUMINACION/EMPOTRADO' },
                    {type: 'link', label: 'Farol', url: './shop/ILUMINACION/FAROL' },
                    {type: 'link', label: 'Luminaria', url: './shop/ILUMINACION/LUMINARIA' },
                    {type: 'link', label: 'Gabinete', url: './shop/ILUMINACION/GABINETE' },
                    {type: 'link', label: 'Poste', url: './shop/ILUMINACION/POSTE' },
                    {type: 'link', label: 'Lampara', url: './shop/ILUMINACION/LAMPARA' },
                    {type: 'link', label: 'Suburbana', url: './shop/ILUMINACION/SUBURBANA' },
                    {type: 'link', label: 'Plafon', url: './shop/ILUMINACION/PLAFON' },
                    {type: 'link', label: 'Reflector', url: './shop/ILUMINACION/REFLECTOR' },
                    {type: 'link', label: 'Riel', url: './shop/ILUMINACION/RIEL' },
                    {type: 'link', label: 'Tira Led', url: './shop/ILUMINACION/TIRA LED' },
                    {type: 'link', label: 'Tubo Led', url: './shop/ILUMINACION/TUBO LED' },
                    {type: 'link', label: 'Panel Led', url: './shop/ILUMINACION/PANEL LED' },
                ]
            },
            {
                type: 'nolink', label: 'Material Electrico', url: '', children: [
                    {type: 'link', label: 'Clavija', url: './shop/MATERIAL ELECTRICO/CLAVIJA' },
                    {type: 'link', label: 'Apagador', url: './shop/MATERIAL ELECTRICO/APAGADOR' },
                    {type: 'link', label: 'Conector', url: './shop/MATERIAL ELECTRICO/CONECTOR' },
                    {type: 'link', label: 'Campana', url: './shop/MATERIAL ELECTRICO/CAMPANA' },
                    {type: 'link', label: 'Cinta', url: './shop/MATERIAL ELECTRICO/CINTA' },
                    {type: 'link', label: 'Contacto', url: './shop/MATERIAL ELECTRICO/CONTACTO' },
                    {type: 'link', label: 'Extersiòn', url: './shop/MATERIAL ELECTRICO/EXTENSION' },
                    {type: 'link', label: 'Respuesto', url: './shop/MATERIAL ELECTRICO/REPUESTO' },
                    {type: 'link', label: 'Tapas', url: './shop/MATERIAL ELECTRICO/TAPAS' },
                    {type: 'link', label: 'Timbre', url: './shop/MATERIAL ELECTRICO/TIMBRE' },
                    {type: 'link', label: 'Toma', url: './shop/MATERIAL ELECTRICO/TOMA' },
                    {type: 'link', label: 'Interfon', url: './shop/MATERIAL ELECTRICO/INTERFON' },
                    {type: 'link', label: 'Placas', url: './shop/MATERIAL ELECTRICO/PLACAS' },
                    {type: 'link', label: 'Socket', url: './shop/MATERIAL ELECTRICO/SOCKET' },
                    {type: 'link', label: 'Timmer', url: './shop/MATERIAL ELECTRICO/TIMMER' },
                    {type: 'link', label: 'Ventilador y extractor', url: './shop/MATERIAL ELECTRICO/VENTILADOR Y EXTRACTOR' },
                    {type: 'link', label: 'Cono Spot', url: './shop/MATERIAL ELECTRICO/CONO SPOT' },
                    {type: 'link', label: 'Multimetro', url: './shop/MATERIAL ELECTRICO/MULTIMETRO' },
                    {type: 'link', label: 'Adaptador', url: './shop/MATERIAL ELECTRICO/ADAPTADOR' },
                    {type: 'link', label: 'Pastillas', url: './shop/MATERIAL ELECTRICO/PASTILLAS' },
                ]
            },
            {
                type: 'nolink', label: 'Distribución y Control', url: '', children: [
                    {type: 'link', label: 'Adaptador', url: './shop/DISTRIBUCION Y CONTROL/ADAPTADOR' },
                    {type: 'link', label: 'Balastra', url: './shop/DISTRIBUCION Y CONTROL/BALASTRA' },
                    {type: 'link', label: 'Fuente', url: './shop/DISTRIBUCION Y CONTROL/FUENTE' },
                    {type: 'link', label: 'Divisor', url: './shop/DISTRIBUCION Y CONTROL/DIVISOR' },
                    {type: 'link', label: 'Fotocelda', url: './shop/DISTRIBUCION Y CONTROL/FOTOCELDA' },
                    {type: 'link', label: 'Gabinete', url: './shop/DISTRIBUCION Y CONTROL/GABINETE' },
                    {type: 'link', label: 'Pastillas', url: './shop/DISTRIBUCION Y CONTROL/PASTILLAS' },
                    {type: 'link', label: 'Sensor', url: './shop/DISTRIBUCION Y CONTROL/SENSOR' },
                    {type: 'link', label: 'Centro de carga', url: './shop/DISTRIBUCION Y CONTROL/CENTRO DE CARGA' },
                    {type: 'link', label: 'Probador', url: './shop/DISTRIBUCION Y CONTROL/PROBADOR' },
                    {type: 'link', label: 'Pastillas', url: './shop/DISTRIBUCION Y CONTROL/PASTILLAS' },
                    {type: 'link', label: 'Motor', url: './shop/DISTRIBUCION Y CONTROL/MOTOR' },
                    {type: 'link', label: 'Interruptor', url: './shop/DISTRIBUCION Y CONTROL/INTERRUPTOR' },
                    {type: 'link', label: 'Fuente', url: './shop/DISTRIBUCION Y CONTROL/FUENTE' },
                    {type: 'link', label: 'Controlador', url: './shop/DISTRIBUCION Y CONTROL/CONTROLADOR' },
                    {type: 'link', label: 'Bomba', url: './shop/DISTRIBUCION Y CONTROL/BOMBA' },
                ]
            },
            {
                type: 'nolink', label: 'Conductores', url: '', children: [
                    {type: 'link',  label: 'Cable', url: './shop/CONDUCTORES/CABLE' },
                    {type: 'link',  label: 'Empalmes', url: './shop/CONDUCTORES/EMPALMES' },
                    {type: 'link',  label: 'Espiral', url: './shop/CONDUCTORES/ESPIRAL' },
                ]
            },
            {
                type: 'nolink', label: 'Ferreteria', url: '', children: [
                    {type: 'link', label: 'Broca', url: './shop/FERRETERIA/BROCA' },
                    {type: 'link', label: 'Cincho', url: './shop/FERRETERIA/CINCHO' },
                    {type: 'link', label: 'Campana', url: './shop/FERRETERIA/CAMPANA' },
                    {type: 'link', label: 'Herramienta', url: './shop/FERRETERIA/HERRAMIENTA' },
                    {type: 'link', label: 'Terminal', url: './shop/FERRETERIA/TERMINAL' },
                    {type: 'link', label: 'Tornilleria', url: './shop/FERRETERIA/TORNILLERIA' },
                    {type: 'link', label: 'Zapata', url: './shop/FERRETERIA/ZAPATA' },
                    {type: 'link', label: 'Derivador', url: './shop/FERRETERIA/DERIVADOR' },
                    {type: 'link', label: 'Varios', url: './shop/FERRETERIA/VARIOS' },
                ]
            },
        ]
    },

    {
        type: 'nolink', label: 'Mi Cuenta', url: '', children: [
            { type: 'link', label: 'Iniciar Sesiòn', url: './account/login' },
        ]
    },
    {
        type: 'nolink', label: 'Contacto', url: './site', children: [
            { type: 'link', label: 'Nosotros', url: './site/about-us' },
            { type: 'link', label: 'Contacto', url: './site/contact-us' },
            { type: 'link', label: 'Terminos y Condiciones', url: './site/terms' },
            { type: 'link', label: 'Preguntas', url: './site/faq' },
        ]
    },
    {
        type: 'nolink', label: 'Servicios', url: './site', children: [
            { type: 'link', label: 'Directorio', url: './site/servicios'},
        ]
    },
    /*{
        type: 'nolink', label: 'Promociones y Eventos', url: './site', children: [
            { type: 'link', label: 'Catalogo', url: './site/catalogo'  }
        ]
    }*/

];

export const mobileMenuLogin: MobileMenuItem[] = [
    {
        type: 'link', label: 'Inicio', url: './'
    },
    {
        type: 'nolink', label: 'Categorias', url: './shop', children: [
            {
                type: 'nolink', label: 'Canalización', url: './shop/products/Canalización', children: [
                    { type: 'link', label: 'Abrazadera', url: './shop/CANALIZACION/ABRAZADERA' },
                    { type: 'link', label: 'Abrazadera', url: './shop/CANALIZACION/ABRAZADERA' },
                    { type: 'link', label: 'Armario', url: './shop/CANALIZACION/ARMARIO' },
                    { type: 'link', label: 'Base', url: './shop/CANALIZACION/BASE' },
                    { type: 'link', label: 'Bote', url: './shop/CANALIZACION/BOTE' },
                    { type: 'link', label: 'Brazo', url: './shop/CANALIZACION/BRAZO' },
                    { type: 'link', label: 'Caja', url: './shop/CANALIZACION/CAJA' },
                    { type: 'link', label: 'Canaleta', url: './shop/CANALIZACION/CANALETA' },
                    { type: 'link', label: 'Codos', url: './shop/CANALIZACION/CODOS' },
                    { type: 'link', label: 'Barra', url: './shop/CANALIZACION/BARRA' },
                    { type: 'link', label: 'Condulet', url: './shop/CANALIZACION/CONDULET' },
                    { type: 'link', label: 'Conduit', url: './shop/CANALIZACION/CONDUIT' },
                    { type: 'link', label: 'Conector', url: './shop/CANALIZACION/CONECTOR' },
                    { type: 'link', label: 'Contador', url: './shop/CANALIZACION/CONTACTOR' },
                    { type: 'link', label: 'Zapata', url: './shop/CANALIZACION/ZAPATA' },
                    { type: 'link', label: 'Cople', url: './shop/CANALIZACION/COPLE' },
                    { type: 'link', label: 'Sello', url: './shop/CANALIZACION/SELLO' },
                    { type: 'link', label: 'Barra', url: './shop/CANALIZACION/BARRA' },
                    { type: 'link', label: 'Coble', url: './shop/CANALIZACION/COPLE' },
                    { type: 'link', label: 'Cuadralet', url: './shop/CANALIZACION/CUADRALET' },
                    { type: 'link', label: 'Monitor', url: './shop/CANALIZACION/MONITOR' },
                    { type: 'link', label: 'Mufa', url: './shop/CANALIZACION/MUFA' },
                    { type: 'link', label: 'Ovalet', url: './shop/CANALIZACION/OVALET' },
                    { type: 'link', label: 'Poliducto', url: './shop/CANALIZACION/POLIDUCTO' },
                    { type: 'link', label: 'Tubo', url: './shop/CANALIZACION/TUBO' },
                    { type: 'link', label: 'Varilla', url: './shop/CANALIZACION/VARILLA' },
                    { type: 'link', label: 'Reducciòn', url: './shop/CANALIZACION/REDUCCION' },
                    { type: 'link', label: 'Ducto', url: './shop/CANALIZACION/DUCTO' },
                    { type: 'link', label: 'Fusible', url: './shop/CANALIZACION/FUSIBLE' },
                    { type: 'link', label: 'Gabinete', url: './shop/CANALIZACION/GABINETE' },
                    { type: 'link', label: 'Guia jala cable', url: './shop/CANALIZACION/GUIA JALA CABLE' },
                    { type: 'link', label: 'Interruptor', url: './shop/CANALIZACION/INTERRUPTOR' },
                ]
            },
            {
                type: 'nolink', label: 'Iluminación', url: './shop', children: [
                    { type: 'link', label: 'Bombillo', url: './shop/ILUMINACION/BOMBILLO' },
                    { type: 'link', label: 'Arbotante', url: './shop/ILUMINACION/ARBOTANTE' },
                    { type: 'link', label: 'Candil', url: './shop/ILUMINACION/CANDIL' },
                    { type: 'link', label: 'Colgante', url: './shop/ILUMINACION/COLGANTE' },
                    { type: 'link', label: 'Empotrado', url: './shop/ILUMINACION/EMPOTRADO' },
                    { type: 'link', label: 'Farol', url: './shop/ILUMINACION/FAROL' },
                    { type: 'link', label: 'Luminaria', url: './shop/ILUMINACION/LUMINARIA' },
                    { type: 'link', label: 'Gabinete', url: './shop/ILUMINACION/GABINETE' },
                    { type: 'link', label: 'Poste', url: './shop/ILUMINACION/POSTE' },
                    { type: 'link', label: 'Lampara', url: './shop/ILUMINACION/LAMPARA' },
                    { type: 'link', label: 'Suburbana', url: './shop/ILUMINACION/SUBURBANA' },
                    { type: 'link', label: 'Plafon', url: './shop/ILUMINACION/PLAFON' },
                    { type: 'link', label: 'Reflector', url: './shop/ILUMINACION/REFLECTOR' },
                    { type: 'link', label: 'Riel', url: './shop/ILUMINACION/RIEL' },
                    { type: 'link', label: 'Tira Led', url: './shop/ILUMINACION/TIRA LED' },
                    { type: 'link', label: 'Tubo Led', url: './shop/ILUMINACION/TUBO LED' },
                    { type: 'link', label: 'Panel Led', url: './shop/ILUMINACION/PANEL LED' },
                ]
            },
            {
                type: 'nolink', label: 'Material Electrico', url: './shop', children: [
                    { type: 'link', label: 'Clavija', url: './shop/MATERIAL ELECTRICO/CLAVIJA' },
                    { type: 'link', label: 'Apagador', url: './shop/MATERIAL ELECTRICO/APAGADOR' },
                    { type: 'link', label: 'Conector', url: './shop/MATERIAL ELECTRICO/CONECTOR' },
                    { type: 'link', label: 'Campana', url: './shop/MATERIAL ELECTRICO/CAMPANA' },
                    { type: 'link', label: 'Cinta', url: './shop/MATERIAL ELECTRICO/CINTA' },
                    { type: 'link', label: 'Contacto', url: './shop/MATERIAL ELECTRICO/CONTACTO' },
                    { type: 'link', label: 'Extersiòn', url: './shop/MATERIAL ELECTRICO/EXTENSION' },
                    { type: 'link', label: 'Respuesto', url: './shop/MATERIAL ELECTRICO/REPUESTO' },
                    { type: 'link', label: 'Tapas', url: './shop/MATERIAL ELECTRICO/TAPAS' },
                    { type: 'link', label: 'Timbre', url: './shop/MATERIAL ELECTRICO/TIMBRE' },
                    { type: 'link', label: 'Toma', url: './shop/MATERIAL ELECTRICO/TOMA' },
                    { type: 'link', label: 'Interfon', url: './shop/MATERIAL ELECTRICO/INTERFON' },
                    { type: 'link', label: 'Placas', url: './shop/MATERIAL ELECTRICO/PLACAS' },
                    { type: 'link', label: 'Socket', url: './shop/MATERIAL ELECTRICO/SOCKET' },
                    { type: 'link', label: 'Timmer', url: './shop/MATERIAL ELECTRICO/TIMMER' },
                    { type: 'link', label: 'Ventilador y extractor', url: './shop/MATERIAL ELECTRICO/VENTILADOR Y EXTRACTOR' },
                    { type: 'link', label: 'Cono Spot', url: './shop/MATERIAL ELECTRICO/CONO SPOT' },
                    { type: 'link', label: 'Multimetro', url: './shop/MATERIAL ELECTRICO/MULTIMETRO' },
                    { type: 'link', label: 'Adaptador', url: './shop/MATERIAL ELECTRICO/ADAPTADOR' },
                    { type: 'link', label: 'Pastillas', url: './shop/MATERIAL ELECTRICO/PASTILLAS' },
                ]
            },
            {
                type: 'nolink', label: 'Distribución y Control', url: './shop', children: [
                    { type: 'link', label: 'Adaptador', url: './shop/DISTRIBUCION Y CONTROL/ADAPTADOR' },
                    { type: 'link', label: 'Balastra', url: './shop/DISTRIBUCION Y CONTROL/BALASTRA' },
                    { type: 'link', label: 'Fuente', url: './shop/DISTRIBUCION Y CONTROL/FUENTE' },
                    { type: 'link', label: 'Divisor', url: './shop/DISTRIBUCION Y CONTROL/DIVISOR' },
                    { type: 'link', label: 'Fotocelda', url: './shop/DISTRIBUCION Y CONTROL/FOTOCELDA' },
                    { type: 'link', label: 'Gabinete', url: './shop/DISTRIBUCION Y CONTROL/GABINETE' },
                    { type: 'link', label: 'Pastillas', url: './shop/DISTRIBUCION Y CONTROL/PASTILLAS' },
                    { type: 'link', label: 'Sensor', url: './shop/DISTRIBUCION Y CONTROL/SENSOR' },
                    { type: 'link', label: 'Centro de carga', url: './shop/DISTRIBUCION Y CONTROL/CENTRO DE CARGA' },
                    { type: 'link', label: 'Probador', url: './shop/DISTRIBUCION Y CONTROL/PROBADOR' },
                    { type: 'link', label: 'Pastillas', url: './shop/DISTRIBUCION Y CONTROL/PASTILLAS' },
                    { type: 'link', label: 'Motor', url: './shop/DISTRIBUCION Y CONTROL/MOTOR' },
                    { type: 'link', label: 'Interruptor', url: './shop/DISTRIBUCION Y CONTROL/INTERRUPTOR' },
                    { type: 'link', label: 'Fuente', url: './shop/DISTRIBUCION Y CONTROL/FUENTE' },
                    { type: 'link', label: 'Controlador', url: './shop/DISTRIBUCION Y CONTROL/CONTROLADOR' },
                    { type: 'link', label: 'Bomba', url: './shop/DISTRIBUCION Y CONTROL/BOMBA' },
                ]
            },
            {
                type: 'nolink', label: 'Conductores', url: './shop', children: [
                    { type: 'link', label: 'Cable', url: './shop/CONDUCTORES/CABLE' },
                    { type: 'link', label: 'Empalmes', url: './shop/CONDUCTORES/EMPALMES' },
                    { type: 'link', label: 'Espiral', url: './shop/CONDUCTORES/ESPIRAL' },
                ]
            },
            {
                type: 'nolink', label: 'Ferreteria', url: './shop', children: [
                    { type: 'link', label: 'Broca', url: './shop/FERRETERIA/BROCA' },
                    { type: 'link', label: 'Cincho', url: './shop/FERRETERIA/CINCHO' },
                    { type: 'link', label: 'Campana', url: './shop/FERRETERIA/CAMPANA' },
                    { type: 'link', label: 'Herramienta', url: './shop/FERRETERIA/HERRAMIENTA' },
                    { type: 'link', label: 'Terminal', url: './shop/FERRETERIA/TERMINAL' },
                    { type: 'link', label: 'Tornilleria', url: './shop/FERRETERIA/TORNILLERIA' },
                    { type: 'link', label: 'Zapata', url: './shop/FERRETERIA/ZAPATA' },
                    { type: 'link', label: 'Derivador', url: './shop/FERRETERIA/DERIVADOR' },
                    { type: 'link', label: 'Varios', url: './shop/FERRETERIA/VARIOS' },
                ]
            },
        ]
    },

    {
        type: 'nolink', label: 'Mi Cuenta', url: './account', children: [
            { type: 'link', label: 'Dashboard', url: './account/dashboard' },
            //{ type: 'link', label: 'Editar Perfil', url: './account/profile' },
            { type: 'link', label: 'Historial de Pedidos', url: './account/orders' }
        ]
    },
    {
        type: 'nolink', label: 'Contacto', url: './site', children: [
            { type: 'link', label: 'Nosotros', url: './site/about-us' },
            { type: 'link', label: 'Contacto', url: './site/contact-us' },
            { type: 'link', label: 'Terminos y Condiciones', url: './site/terms' },
            { type: 'link', label: 'Preguntas', url: './site/faq' },
        ]
    },
    {
        type: 'nolink', label: 'Servicios', url: './site', children: [
            { type: 'link', label: 'Directorio', url: './site/servicios' },
        ]
    },
    {
        type: 'nolink', label: 'Promociones y Eventos', url: './site', children: [
            { type: 'link', label: 'Catalogo', url: './site/catalogo' }
        ]
    }

];
