import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// modules
import { SharedModule } from '../../shared/shared.module';

// components
import { DepartmentsComponent } from './components/departments/departments.component';
import { DropcartComponent } from './components/dropcart/dropcart.component';
import { HeaderComponent } from './header.component';
import { LinksComponent } from './components/links/links.component';
import { MegamenuComponent } from './components/megamenu/megamenu.component';
import { MenuComponent } from './components/menu/menu.component';
import { NavComponent } from './components/nav/nav.component';
import { SearchComponent } from './components/search/search.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { NgbdModalContent, SearchBarComponent,  } from './components/search-bar/search-bar.component';
// Material
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { NgAisModule } from 'angular-instantsearch';

// bootstra
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
@NgModule({
    declarations: [
        // components
        DepartmentsComponent,
        DropcartComponent,
        HeaderComponent,
        LinksComponent,
        MegamenuComponent,
        MenuComponent,
        NavComponent,
        SearchComponent,
        TopbarComponent,
        NgbdModalContent,
        SearchBarComponent
    ],
    imports: [
        // modules (angular)
        CommonModule,
        RouterModule,
        // modules
        SharedModule,
        // Material
        MatButtonModule, MatIconModule, MatMenuModule, MatPaginatorModule, MatTableModule, MatFormFieldModule, MatInputModule,
        // ng-bootstrap
        NgbModule,
        NgAisModule,
        NgxPaginationModule,
        FormsModule
    ],
    exports: [
        // components
        HeaderComponent,
        SearchBarComponent
    ],
    entryComponents: [NgbdModalContent]
})
export class HeaderModule { }
