import { Injectable } from '@angular/core';
//import * as faker from 'faker';
import { OrderUser } from '../../shared/interfaces/orderUser';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';

type ordersCollection = AngularFirestoreCollection<OrderUser[]>;
type ordersDocument = AngularFirestoreDocument<OrderUser>;

@Injectable()
export class OrdersService {

  constructor(
    private afs: AngularFirestore
  ) { }

  orders(uid?: string): ordersCollection {
    if(uid) {
      return this.afs.collection<OrderUser[]>('orders', ref => ref.where('uid', '==', uid).orderBy("create_at", "desc"));
    }
    return this.afs.collection<OrderUser[]>('orders', ref => ref.orderBy("create_at", "desc"));
  }

  order(id: string): ordersDocument {
    return this.afs.doc<OrderUser>(`orders/${id}`);
  }

  save(order: OrderUser): Promise<any> {
    return this.orders().doc(order.id).set(Object.assign({}, order));
  }
}
