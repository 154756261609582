import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StoreService {
    address = 'Cupatitzio 362, La Tamacua, 60090 Uruapan, Michoacán.';
    email = 'proemsa@proemsa.com';
    phone = ['(452) 519 0685'];
    hours = 'Lunes - Viernes 9:00am - 7:00pm |  Sábados 9:00am - 2:30pm';

    get primaryPhone(): string|null {
        return this.phone.length > 0 ? this.phone[0] : null;
    }

    constructor() { }
}
